<template>
  <div>
    <div class="mb-2">
      <div class="card theme-wizard mb-2">
        <div class="border-bottom pt-2">
          <ul class="nav justify-content-between nav-wizard">
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">1</span></span
                ><span class="d-none d-md-block mt-1 fs--1"
                  >Create Locations</span
                ></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">2</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Categories</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Groups</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Menu Items</span></a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div
              class="row g-3 d-flex tab-pane active"
              role="tabpanel"
              aria-labelledby="bootstrap-wizard-tab1"
              id="bootstrap-wizard-tab1"
            >
              <div class="col-3 text-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_flagged_2uty.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_confirmation_re_b6q5.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_shopping_app_flsj.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_product_iteration_kjok.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-sm-4 col-xs-4 align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Menu Items
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Generate menu items
            </p>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <button
                class="btn btn-sm btn-falcon-warning ms-2 ms-auto"
                type="file"
                data-bs-toggle="modal"
                data-bs-target="#import-modal"
                data-toggle="tooltip"
                data-placement="top"
                title="Import product"
              >
                <i class="fas fa-file-excel"></i> Import
              </button>

              <button
                class="btn btn-sm btn-falcon-warning ms-2"
                type="file"
                data-toggle="tooltip"
                data-placement="top"
                title="Export product"
                @click="exportData"
              >
                <i class="fas fa-file-excel"></i> Export
              </button>
              <button
                class="btn btn-sm btn-rounded btn-falcon-warning ms-2"
                type="button"
                @click="addNew"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                data-toggle="tooltip"
                data-placement="top"
                title="Add new product"
              >
                <i class="fas fa-plus"></i>
                New
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-warning ms-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh page"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Refresh
              </button>
              <button
                @click="goToBarcode"
                class="btn btn-sm btn-falcon-warning ms-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Open barcode page"
              >
                <i class="fa-solid fa-barcode"></i> Barcodes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <div
          id="tableExample3"
          data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
        >
          <div class="row justify-content-start g-0">
            <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3">
              <select
                class="form-select"
                id="recipient-name"
                type="text"
                @change="selectOrderType"
                style="width: auto"
              >
                <option value="all">All Items</option>
                <option
                  v-for="(item, i) in order_types"
                  :key="i"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div
              class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3 d-flex ms-auto"
            >
              <div class="input-group ms-auto me-2">
                <input
                  class="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search..."
                  aria-label="search"
                  v-model="search_term"
                  v-on:keyup="searchItem"
                />
                <div class="input-group-text bg-transparent">
                  <span class="fa fa-search fs--1 text-600"></span>
                </div>
              </div>
              <button class="btn btn-falcon-warning mx-1" @click="goToPOS">
                POS
              </button>
            </div>
          </div>
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <th class="sort" data-sort="name">Item #</th>
                  <th class="sort" data-sort="email">Photo</th>
                  <th class="sort" data-sort="age">Name</th>
                  <!-- <th class="sort" data-sort="age">Group</th> -->
                  <th class="sort" data-sort="age">B.Price</th>
                  <th class="sort" data-sort="age">S.Price</th>
                  <th class="sort" data-sort="age">WS.Price</th>
                  <!-- <th class="sort" data-sort="age">Offer Price</th> -->
                  <th class="sort" data-sort="age">Details</th>
                  <th class="sort" data-sort="age">Stock Amount</th>
                  <th class="sort" data-sort="age">Units</th>
                  <th class="sort" data-sort="age">Action</th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(item, i) in resources" :key="i">
                  <td>{{ item.id }}</td>
                  <td>
                    <div class="avatar avatar-xl">
                      <img
                        v-if="item.image"
                        class="rounded-circle"
                        :src="item.image"
                        alt="Product Image"
                        height="32"
                      />
                      <img
                        v-else
                        class="rounded-circle"
                        src="assets/img/no-image.jpg"
                        alt="Product Image"
                        height="32"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="my-2">
                      <strong>{{ item.name.toUpperCase() }}</strong>
                      <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                    </div>
                  </td>
                  <!-- <td>{{ item.group ? item.group.name : "" }}</td> -->
                  <td>
                    <input
                      :value="item.buy_price"
                      @change="updateBP($event, item)"
                    />
                  </td>
                  <td>
                    <input
                      :value="item.price"
                      @change="updateSP($event, item)"
                    />
                  </td>
                  <td>
                    <input
                      :value="item.ws_price"
                      @change="updateWP($event, item)"
                    />
                  </td>
                  <!-- <td>{{ item.offer_price }}</td> -->
                  <td>{{ item.description }}</td>
                  <td>{{ item.stock_amount }}</td>
                  <td>{{ item.unit_name }}</td>
                  <td>
                    <div class="dropdown font-sans-serif position-static">
                      <button
                        class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                        type="button"
                        id="order-dropdown-0"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="fas fa-ellipsis-h fs--1"></span>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-end border py-0"
                        aria-labelledby="order-dropdown-0"
                      >
                        <div class="py-2">
                          <a
                            class="dropdown-item"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                            @click="editItem(item)"
                          >
                            Edit
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            v-if="user.permissions.includes('void-ticket')"
                            class="dropdown-item text-danger"
                            role="button"
                            @click="deleteResource(item)"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div>
        </div>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="editModal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              id="edit-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">
                {{ formData.id ? `Edit` : `New` }} Products
              </h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select at least one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Select Group</label
                        >
                        <select
                          class="form-select"
                          id="recipient-name"
                          type="text"
                          v-model="formData.group_id"
                          @change="groupSelected"
                          :class="
                            v$.formData.group_id.$dirty &&
                            v$.formData.group_id.$invalid
                              ? `is-invalid`
                              : v$.formData.group_id.$dirty
                              ? `is-valid`
                              : ``
                          "
                        >
                          <option
                            v-for="(item, i) in groups"
                            :key="i"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          v-for="error of v$.formData.group_id.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty &&
                            v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty &&
                            v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >WS Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.ws_price"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Offer Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.offer_price"
                          :class="
                            v$.formData.offer_price.$dirty &&
                            v$.formData.offer_price.$invalid
                              ? `is-invalid`
                              : v$.formData.offer_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          disabled
                          placeholder="e.ge 10"
                          v-model="formData.stock_amount"
                        />
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <!-- ORDER TYPES QUANTITIES  -->
                    <div
                      v-for="(item, i) in formData.selected_order_types"
                      :key="i"
                      class="row"
                    >
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >{{ item.name.toUpperCase() }} Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          :value="
                            location_quantities.find(
                              (el) => el.location_id == item.id
                            )
                              ? location_quantities.find(
                                  (el) => el.location_id == item.id
                                ).quantity
                              : 0
                          "
                          @change="addLocationQty($event, item)"
                        />
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Re-order level</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          :value="
                            location_quantities.find(
                              (el) => el.location_id == item.id
                            )
                              ? location_quantities.find(
                                  (el) => el.location_id == item.id
                                ).reorder
                              : 0
                          "
                          @change="addLocationReorderQty($event, item)"
                        />
                      </div>
                      <hr class="mt-2" />
                    </div>
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Barcode</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Photo</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Description</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                      <div
                        v-if="formData.barcode && formData.barcode.length > 0"
                        class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                        <div class="border border-success text-center">
                          <label
                            class="col-form-label mb-0 text-decoration-underline"
                            for="recipient-name"
                            >{{
                              formData.name ? formData.name.toUpperCase() : ""
                            }}</label
                          >
                          <br />
                          <p>{{ formData.description }}</p>
                          <div>
                            <vue-barcode
                              :value="formData.barcode"
                              :height="50"
                            />
                          </div>
                          <span>{{ formatToCurrency(formData.price) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Other Actions</div>
                </div>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.visible ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Active?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleSellable"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.sellable ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Sellable?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleVAT"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.vat ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>VAT(16%)
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <label class="col-form-label" for="message-text"
                      >Size</label
                    >
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                      v-model="formData.unit_name"
                    >
                      <option value="">Unit...</option>
                      <option
                        v-for="(item, i) in menu_item_sizes"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </li>
                  <hr />

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Select Locations</div>
                  </div>
                  <div
                    class="border"
                    :class="
                      show_location_error ? 'border-danger' : 'border-success'
                    "
                  >
                    <li
                      v-for="(item, i) in order_types"
                      :key="i"
                      class="nav-item me-2 me-lg-0 my-2"
                    >
                      <a
                        @click="addToLocations(item)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.order_types &&
                          formData.order_types.includes(item.id)
                            ? `bg-info text-white`
                            : ``
                        "
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </div>

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Actions</div>
                  </div>
                  <button class="d-flex ms-uto btn btn-falcon-primary">
                    Print Barcode
                  </button>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="formData.id"
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
            <button
              v-else
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start import modal  -->
    <div
      class="modal fade"
      id="import-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="import-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
            id="import-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input
                          type="file"
                          id="import-excel"
                          @change="importExcel()"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <a
                          href="https://docs.google.com/spreadsheets/d/1nVyV3pfREpDOhZgaQk9zhEt-G8GvX8QU/edit?usp=sharing&ouid=111166152502013029101&rtpof=true&sd=true"
                          target="_blank"
                          class="text-decoration-underline"
                          >Click here to download the excel template</a
                        >
                      </div>
                    </div>
                    <hr class="my-2" />
                    <div class="table-responsive">
                      <table class="table">
                        <caption>
                          List of items
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">Description</th>
                            <th scope="col">B.P</th>
                            <th scope="col">S.P</th>
                            <th scope="col">WS.P</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Units</th>
                            <th scope="col">Category</th>
                            <th scope="col">Taxable</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in import_resources" :key="i">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ item[0] }}</td>
                            <td>{{ item[1] }}</td>
                            <td>{{ item[2] }}</td>
                            <td>{{ item[3] }}</td>
                            <td>{{ item[4] }}</td>
                            <td>{{ item[5] }}</td>
                            <td>{{ item[6] }}</td>
                            <td>{{ item[7] }}</td>
                            <td>{{ item[8] }}</td>
                            <td>{{ item[9] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-warning ms-2 rounded-pill h1 d-block"
              type="button"
              @click="uploadData"
            >
              <span class="d-none d-sm-inline-block ms-1">
                <i class="fa fa-cloud"></i> Upload</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End import modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import Pagination from "v-pagination-3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import VueBarcode from "@chenfengyuan/vue-barcode";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuItemsService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import OrderTypeService from "../services/order_types.service";

import readXlsFile from "read-excel-file";
import exportXlsFile from "export-from-json";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        price: { required },
        group_id: { required },
        buy_price: { required },
        offer_price: {required},
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    Pagination,
    VueBarcode,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      per_page: 18,
      current_page: 0,
      records: 0,
      search_term: "",
      file: "",
      order_type: "all",
      order_types: [],
      filename: "",
      selected: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      selected_items: "",
      import_resources: [],
      menu_item_sizes: [],
      productCategories: [],
      selected_category: "",
      formData: {
        vat: 0,
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
        description: "",
        order_types: [],
        barcode: "",
        sellable: 0,
        stock_amount: 0,
        offer_price: 0,
        selected_order_types: [],
      },
      resources: [],
      location_quantities: [],
      orders_table_key: 0,
      show_location_error: false,
      searchField: ["id", "name", "price"],
      headers: [
        { text: "#", value: "id", sortable: true, width: 10 },
        { text: "Photo", value: "image", sortable: true, width: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Group", value: "group_id", sortable: true },
        { text: "B.Price", value: "buy_price", sortable: true },
        { text: "S.Price", value: "price", sortable: true },
        { text: "Details", value: "description", sortable: true },
        { text: "Barcode", value: "barcode", sortable: true },
        { text: "Stock Amount", value: "stock_amount", sortable: true },
        { text: "Units", value: "unit_name", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getAllOrderTypes();
    await this.getGroups();
    await this.getMenuItemSize();
    this.$store.commit("SET_LOADING", false);
    this.getMenuItems(1);
  },

  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    hideModals() {
      $("#add-modal-close").click();
      $("#edit-modal-close").click();
      $("#import-modal-close").click();
    },
    updateBP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateBP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    updateSP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateSP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    updateWP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateSP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    closeUpdateModal() {
      $("#edit-modal-close").click();
    },
    addLocationReorderQty(e, location) {
      let data = {
        reorder: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.reorder = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
    },
    addLocationQty(e, location) {
      let data = {
        reorder: 0,
        quantity: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addLocationQtyInit(location) {
      let data = {
        reorder: 0,
        quantity: parseInt(0),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(0);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    myCallback(page) {
      this.page = page;
      this.getMenuItems(page);
    },
    async uploadData() {
      this.$store.commit("SET_LOADING", true);
      let excelData = await this.import_resources.map((element) => {
        if (element[0]) {
          return {
            name: element[0],
            barcode: element[1],
            description: element[2],
            buy_price: element[3],
            price: element[4],
            ws_price: element[5],
            stock_amount: element[6],
            unit_name: element[7],
            category: element[8],
            vat: element[9] == true ? 1 : 0,
          };
        }
      });
      let jsonData = JSON.stringify(excelData);
      let formData = {
        location: this.order_type,
        excel_data: jsonData,
      };
      await MenuItemsService.import(formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            this.page = 1;
            this.getMenuItems(1);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      toast.success("Items Uploaded successfully!");
      this.$store.commit("SET_LOADING", false);
    },
    importExcel() {
      const input = document.getElementById("import-excel");
      readXlsFile(input.files[0]).then((rows) => {
        this.import_resources = rows.filter(
          (item, index) => index != 0 && item[1]
        );
      });
    },
    async exportData() {
      this.$store.commit("SET_LOADING", true);
      const data = await MenuItemsService.all(this.order_type).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      const fileName = "BullaPOs Expoorted Products";
      const exportType = exportXlsFile.types.xls; // xls
      exportXlsFile({ data, fileName, exportType });
      this.$store.commit("SET_LOADING", false);
    },
    searchItem() {
      this.page = 1;
      this.current_page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    goToBarcode() {
      this.$router.push("/barcode");
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    addNew() {
      this.v$.formData.$reset();
      this.formData = {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        description: "",
        order_types: [],
      };
      this.file = "";
      this.filename = "";
      this.show_location_error = false;
      this.formData.selected_order_types = [];
    },
    editItem(item) {
      this.formData = item;
      if (item.locations) {
        this.location_quantities = item.locations.map((el) => {
          return {
            reorder: el.pivot.reorder,
            quantity: el.pivot.quantity,
            location_id: el.pivot.order_type_id,
          };
        });
      } else {
        this.location_quantities = [];
        this.formData.order_types = [];
      }
      if (item.order_types?.length > 0) {
        this.formData.selected_order_types = this.order_types.filter((el) =>
          item.order_types.includes(el.id)
        );
      } else {
        this.formData.selected_order_types = [];
      }
      this.show_location_error = false;
    },

    addToLocations(location) {
      if (this.formData.order_types.length > 0) {
        if (
          this.formData.order_types &&
          this.formData.order_types.includes(location.id)
        ) {
          this.formData.order_types = this.formData.order_types.filter(
            (el) => el != location.id
          );
          this.formData.selected_order_types =
            this.formData.selected_order_types.filter(
              (el) => el.id != location.id
            );
          this.location_quantities = this.location_quantities.filter(
            (el) => el.location_id != location.id
          );
        } else {
          if (this.formData.order_types) {
            this.formData.order_types.push(location.id);
          } else {
            this.formData.order_types = [];
            this.formData.order_types.push(location.id);
          }
          this.formData.selected_order_types.push(location);
        }
      } else {
        this.formData.order_types = [];
        this.formData.order_types.push(location.id);
        this.formData.selected_order_types.push(location);
      }

      this.formData.STOCK_AMOUNT = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
     this. addLocationQtyInit(location);
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
    },

    async selectOrderType(e) {
      this.order_type = e.target.value;
      await this.getMenuItems(this.page);
      this.$store.commit("SET_LOADING", false);
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItems() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItems(page) {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.byOrder(page, this.order_type).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            console.log("AUTO GRP SELECT", response.data.data.at(0).id);
            this.formData.GROUP_ID = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      console.log(this.formData);
      this.formData.visible = !this.formData.visible;
    },
    toggleVAT() {
      this.formData.vat = !this.formData.vat;
    },
    toggleSellable() {
      this.formData.sellable = !this.formData.sellable;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.$touch();
      if (this.formData.order_types.length == 0) {
        this.show_location_error = true;
        return;
      }
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("order_types", this.formData.order_types);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price ? this.formData.ws_price : 0);
      formData.append("offer_price", this.formData.offer_price ? this.formData.offer_price : 0);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append(
        "location_quantities",
        JSON.stringify(this.location_quantities)
      );
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      MenuItemsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            this.page = 1;
            this.current_page = 1;
            this.getMenuItems(this.page);
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
            this.hideModals();
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.order_types.length == 0) {
        this.show_location_error = true;
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", this.formData.id);
      formData.append("order_types", this.formData.order_types);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price ? this.formData.ws_price : 0);
      formData.append("offer_price", this.formData.offer_price ? this.formData.offer_price : 0);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append(
        "location_quantities",
        JSON.stringify(this.location_quantities)
      );
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      MenuItemsService.update2(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getMenuItems(this.page);
            this.closeUpdateModal();
            this.hideModals();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(resource) {
      console.log(resource);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuItemsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
              this.getMenuItems(this.page);
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.index().then(
        (response) => {
          console.log("getdata");
          console.group(response);
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
